import React from "react";

const ExternalIcon = () => (
  <svg width="16px" height="16px" viewBox="0 0 16 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Fill 1</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="0-0-Home" transform="translate(-624.000000, -3012.000000)" fill="#FFFFFF">
            <path d="M632.386431,3012 L635.246804,3014.86037 L630.973414,3019.13374 L632.875275,3021.0356 L637.148666,3016.76219 L640,3019.61354 L640,3012 L632.386431,3012 Z M624,3012 L624,3027.9967 L639.9967,3027.9967 L639.9967,3022.00131 L637.966171,3022.00131 L637.966171,3025.96615 L626.030529,3025.96615 L626.030529,3014.03053 L630.019063,3014.03053 L630.019063,3012 L624,3012 Z" id="Fill-1"></path>
        </g>
    </g>
</svg>
);

export default ExternalIcon;
