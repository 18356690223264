import { Link, StaticQuery, graphql } from "gatsby";
import React, { useState, useEffect } from "react";
import Icon from "./icon";
import { cn } from "../lib/helpers";
import Container from "./container";
import { useCookies } from 'react-cookie';
import Helmet from 'react-helmet';
import PortableText from "./portableText";

import * as styles from "../styles/components/header.module.css";

const query = graphql`
  query HeaderQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      _rawSiteAlert
      siteCookies
    }
    mainnav: allSanityNav(filter: { title: { eq: "Main Navigation" } }) {
      edges {
        node {
          navItems {
            text
            _key
            navItemUrl {
              linkUrl
              externalContent
            }
          }
        }
      }
    }
  }
`;

const Header = () => {

  // determined if page has scrolled and if the view is on mobile
  const [state, setState] = useState({
    scrolled: false,
    scrolledBanner: false,
  });

  const [cookies, setCookie] = useCookies(['hideSiteAlert', 'hideCookieMsg']);

  const [showNav, setShowNav] = useState(false);

  function handleShowNav() {
    setShowNav(true);
  }

  function handleHideNav() {
    setShowNav(false);
  }

  function handleHideSiteAlert() {
    setCookie('hideSiteAlert', true);
  }

  function handleHideCookieMsg() {
    setCookie('hideCookieMsg', true);
  }

  // change state on scroll
  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.pageYOffset > 50;
      const isScrolledBanner = window.pageYOffset > window.innerHeight;
      if (isScrolled !== state.scrolled) {
        setState({
          ...state,
          scrolled: !state.scrolled,
        });
      }
      if (isScrolledBanner !== state.scrolledBanner) {
        setState({
          ...state,
          scrolledBanner: !state.scrolledBanner,
        });
      }
    };
    handleScroll();
    document.addEventListener('scroll', handleScroll);
    return () => {
      // clean up the event handler when the component unmounts
      document.removeEventListener('scroll', handleScroll);
    };
  }, [state, state.scrolled, state.scrolledBanner]);

  return (
  <StaticQuery
    query={query}
    render={data => (
      <>
      <Helmet>
        <html className={showNav && styles.menuOpen} lang="en" />
      </Helmet>

      <header className={styles.root} data-scroll={state.scrolled ? "" : "top"} data-scrollbanner={state.scrolledBanner ? "" : "banner-style"}>
        
        {data.site._rawSiteAlert && !cookies.hideSiteAlert && (
          <span className={styles.siteAlert}>
            <Container>
              <PortableText blocks={data.site._rawSiteAlert} />
            </Container>
            <button
              onClick={handleHideSiteAlert}
            >
              <Icon symbol="close" />
            </button>
          </span>
        )}

        <Container>
          <div className={styles.branding}>
            <Link to="/"><Icon symbol="logo" /></Link>
          </div>

          <button
            className={cn(styles.toggleNavButton, showNav && styles.active)}
            onClick={showNav ? handleHideNav : handleShowNav}
          >
            {showNav ? "Close" : "Menu"}
          </button>

          <nav className={cn(styles.nav, showNav && styles.active)}>
            {data && data.mainnav.edges.map(({node: mainnav}) => (
              <>
                {mainnav.navItems ? (
                  <ul>
                    {mainnav.navItems.map(navItems => (
                      <li key={navItems._key}>
                        {navItems.navItemUrl.externalContent ? 
                          (
                            <a href={navItems.navItemUrl.linkUrl} target="_blank" rel="noreferrer">{navItems.text}<Icon symbol="external" onClick={showNav ? handleHideNav : handleShowNav} /></a>
                          ) : (
                            <Link to={`${navItems.navItemUrl.linkUrl}`} onClick={showNav ? handleHideNav : handleShowNav}>{navItems.text}</Link> 
                          )
                        }
                      </li>
                    ))}
                  </ul>
                ) : null}
              </>
            ))}
          </nav>
          <div className={styles.overlay} onClick={showNav ? handleHideNav : handleShowNav} onKeyDown={showNav ? handleHideNav : handleShowNav} role="button" tabIndex={0}><span className="hidden">Close Menu</span></div>
        </Container>

        {data.site.siteCookies && !cookies.hideCookieMsg && (
          <div className={styles.siteCookies}>
            <Container>
              <p>{data.site.siteCookies} <button onClick={handleHideCookieMsg}>Accept</button></p>
            </Container>
          </div>
        )}

      </header>
      </>
    )}
  />
)};

export default Header;
