import React from "react";

const QuestionIcon = () => (
  <svg width="32px" height="36px" viewBox="0 0 32 36" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Culture-&amp;-Media" transform="translate(-1294.000000, -4496.000000)">
            <g id="Group" transform="translate(1294.000000, 4496.000000)">
                <circle id="Oval" fill="#FFFFFF" cx="16" cy="19" r="16"></circle>
                <text id="?" fill="#323232">
                    <tspan x="11.5" y="26">?</tspan>
                </text>
            </g>
        </g>
    </g>
</svg>
);

export default QuestionIcon;
