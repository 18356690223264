import React from "react";

const ResetIcon = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 3</title>
    <defs>
        <polygon id="path-1" points="0 0 32 0 32 31.9999971 0 31.9999971"></polygon>
    </defs>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Thinking" transform="translate(-90.000000, -5467.000000)">
            <g id="Group-3" transform="translate(90.000000, 5467.000000)">
                <mask id="mask-2" fill="white">
                    <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path d="M25.0795956,19.836306 C24.583173,21.0099673 23.8728224,22.0637225 22.9682568,22.9682596 C22.0637197,23.8728253 21.0099644,24.5831473 19.8363317,25.0795699 C18.6204449,25.5938484 17.3297347,25.8546017 15.99997,25.8546017 C14.6702625,25.8546017 13.3795808,25.5938484 12.163694,25.0795699 C10.9900042,24.5831473 9.93627747,23.8728253 9.03174038,22.9682596 C8.12717473,22.0637225 7.41682413,21.0099673 6.92040152,19.836306 C6.40615157,18.6204192 6.14536971,17.3297375 6.14536971,16.0000014 C6.14536971,14.6702653 6.40615157,13.3795551 6.92040152,12.1636683 C7.41682413,10.990007 8.12717473,9.93628032 9.03174038,9.03171467 C9.93627747,8.12714901 10.9900042,7.41679842 12.163694,6.92037581 C13.3782666,6.40666868 14.6675484,6.14597253 15.9957417,6.14540114 L15.9957417,3.98686556 L22.2926466,7.62238412 L15.9957417,11.2579027 L15.9957417,9.08905354 C14.1513272,9.09013918 12.4175049,9.80886062 11.1132242,11.1131699 C9.8078864,12.4184791 9.08902211,14.153987 9.08902211,16.0000014 C9.08902211,17.8459873 9.8078864,19.5814952 11.1132242,20.8868044 C12.4185048,22.192085 14.1540127,22.9109779 15.99997,22.9109779 C17.8459845,22.9109779 19.5814923,22.192085 20.8868015,20.8868044 C22.1921107,19.5814952 22.9110036,17.8459873 22.9110036,16.0000014 L25.8546274,16.0000014 C25.8546274,17.3297375 25.5938456,18.6204192 25.0795956,19.836306 M15.9999971,0 C7.16344176,0 -2.85694415e-06,7.16341604 -2.85694415e-06,16 C-2.85694415e-06,24.8365582 7.16344176,32 15.9999971,32 C24.8365554,32 31.9999971,24.8365582 31.9999971,16 C31.9999971,7.16341604 24.8365554,0 15.9999971,0" id="Fill-1" fill="#FFFFFF" mask="url(#mask-2)"></path>
            </g>
        </g>
    </g>
</svg>
);

export default ResetIcon;
