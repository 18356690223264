import React from "react";

const ArrowLeftCircleIcon = () => (
  <svg width="27px" height="26px" viewBox="0 0 27 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 5</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="1-0-Book" transform="translate(-40.000000, -2115.000000)">
            <g id="Group-6" transform="translate(40.500000, 2109.000000)">
                <g id="Group-5" transform="translate(13.000000, 19.000000) scale(-1, 1) translate(-13.000000, -19.000000) translate(0.000000, 6.000000)">
                    <circle id="Oval" fill="#FFFFFF" cx="13" cy="13" r="13"></circle>
                    <polygon id="Triangle" fill="#323232" transform="translate(17.500000, 13.000000) scale(1, -1) rotate(-270.000000) translate(-17.500000, -13.000000) " points="17.5 10 21.5 16 13.5 16"></polygon>
                    <rect id="Rectangle" fill="#323232" x="6.5" y="12" width="11" height="2"></rect>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default ArrowLeftCircleIcon;
