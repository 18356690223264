import React from "react";

const DocIcon = () => (
  <svg width="14px" height="18px" viewBox="0 0 14 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 4</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Thinking" transform="translate(-211.000000, -6151.000000)" fill="#FFFFFF">
            <g id="Group-4" transform="translate(211.000000, 6151.000000)">
                <path d="M0,0 L0,18 L13.3043478,18 L13.3043478,6.26086957 L7.04347826,0 L0,0 Z M1.17313043,1.17391304 L5.86956522,1.17391304 L5.86956522,6.26086957 L5.86956522,7.43478261 L7.04347826,7.43478261 L12.1304348,7.43478261 L12.1304348,16.826087 L1.17313043,16.826087 L1.17313043,1.17391304 Z M7.04347826,1.66069565 L11.6436522,6.26086957 L7.04347826,6.26086957 L7.04347826,1.66069565 Z" id="Fill-1"></path>
            </g>
        </g>
    </g>
</svg>
);

export default DocIcon;
