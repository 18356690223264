import React from "react";

const ArrowLeftIcon = () => (
  <svg width="31px" height="10px" viewBox="0 0 31 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 2</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Chapter" transform="translate(-39.000000, -546.000000)" fill="#FFFFFF">
            <g id="Group-2" transform="translate(54.750000, 551.000000) scale(-1, 1) translate(-54.750000, -551.000000) translate(39.500000, 546.500000)">
                <polygon id="Triangle" transform="translate(27.500000, 4.500000) scale(1, -1) rotate(-270.000000) translate(-27.500000, -4.500000) " points="27.5 1.5 32 7.5 23 7.5"></polygon>
                <rect id="Rectangle" x="0" y="3.5" width="27" height="2"></rect>
            </g>
        </g>
    </g>
</svg>
);

export default ArrowLeftIcon;
