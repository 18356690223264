import React from "react";

const FacebookIcon = () => (
  <svg width="28px" height="28px" viewBox="0 0 28 28" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="About-Me" transform="translate(-122.000000, -833.000000)">
            <g id="Group" transform="translate(38.000000, 833.000000)">
                <g id="Group-3" transform="translate(84.000000, 0.000000)">
                    <mask id="mask-2" fill="white">
                        <use xlinkHref="#path-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path d="M14,0 C6.26801325,0 0,6.30633152 0,14.0855863 C0,21.1160662 5.11964238,26.9433945 11.8124768,28 L11.8124768,18.1571602 L8.25777483,18.1571602 L8.25777483,14.0855863 L11.8124768,14.0855863 L11.8124768,10.9822797 C11.8124768,7.45220795 13.902649,5.50214715 17.1004901,5.50214715 C18.6322384,5.50214715 20.2343576,5.77732914 20.2343576,5.77732914 L20.2343576,9.2436893 L18.4689669,9.2436893 C16.7298146,9.2436893 16.1875232,10.3293988 16.1875232,11.4433728 L16.1875232,14.0855863 L20.0703444,14.0855863 L19.4496159,18.1571602 L16.1875232,18.1571602 L16.1875232,28 C22.8803576,26.9433945 28,21.1160662 28,14.0855863 C28,6.30633152 21.7319868,0 14,0" id="Fill-1" fill="#FFFFFF"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default FacebookIcon;
