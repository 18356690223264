import { StaticQuery, graphql } from "gatsby";
import ProjectList from "./project-list-footer";
import Container from "./container";
import React, { useLayoutEffect, useRef } from "react";

import * as styles from "../styles/components/footer.module.css";

const query = graphql`
  query FooterQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      footerHeading
      footerProjects {
        _key
        project {
          title
          excerpt
          link {
            externalContent
            linkUrl
          }
        }
      }
    }
  }
`;

const Footer = () => {

  // Check footer height
  const targetElem = useRef(null);
  const styleElem = useRef(null);

  // change height on resize
  useLayoutEffect(() => {
    function updateHeight() {
      styleElem.current.style.paddingBottom = targetElem.current.clientHeight + "px";
    }

    let checkExist = setInterval(function() {
      if (targetElem.current) {
        updateHeight();
        clearInterval(checkExist);
      }
    }, 500); // check every 100ms

    window.addEventListener('resize', updateHeight);
    
    return () => window.removeEventListener('resize', updateHeight);
  }, []);

  return(
  <StaticQuery
    query={query}
    render={data => (
      <footer className={styles.root} ref={styleElem}>
        <div ref={targetElem}>
          <Container>
            {data.site.footerHeading && (
              <div className={styles.heading}>
                <h5 className="h2 small">{data.site.footerHeading}</h5>
              </div>
            )}
            {data.site.footerProjects && (
              <div className={styles.projects}>
                <div className={styles.containerContent}>
                  <ProjectList items={data.site.footerProjects} />
                </div>
              </div>
            )}
            {data.site.title && (
              <div className={styles.copyright}>
                &copy; {new Date().getFullYear()} {data.site.title}
              </div>
            )}
          </Container>
        </div>
      </footer>
    )}
  />
)};

export default Footer;
