import React from "react";

const LinkedInIcon = () => (
  <svg width="26px" height="27px" viewBox="0 0 26 27" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="About-Me" transform="translate(-38.000000, -834.000000)" fill="#FFFFFF" fillRule="nonzero">
            <g id="Group" transform="translate(38.000000, 833.000000)">
                <path d="M24.1428571,27.0108311 C25.164263,27.0108311 26,26.1693133 26,25.1362889 L26,2.88537336 C26,1.85234898 25.164263,1.01083112 24.1428571,1.01083112 L1.85136196,1.01083112 C0.829899379,1.01083112 0,1.85234898 0,2.88537336 L0,25.1362889 C0,26.1693133 0.829899379,27.0108311 1.85136196,27.0108311 L24.1428571,27.0108311 Z M5.93126134,9.19386683 C4.69511196,9.19386683 3.69688634,8.18986031 3.69688634,6.95949183 C3.69688634,5.72912336 4.69511196,4.72511683 5.93126134,4.72511683 C7.16162981,4.72511683 8.16563634,5.72912336 8.16563634,6.95949183 C8.16563634,8.19564121 7.16741071,9.19386683 5.93126134,9.19386683 Z M14.1316964,23.2965454 L10.2781023,23.2965454 L10.2781023,10.888521 L13.9749887,10.888521 L13.9749887,12.5831752 L14.0272435,12.5831752 C14.5437273,11.6081299 15.8031137,10.5809431 17.6776559,10.5809431 C21.5776672,10.5809431 22.3031137,13.1519139 22.3031137,16.4947484 L22.3031137,23.2965454 L18.4495762,23.2965454 L18.4495762,17.2608311 C18.4495762,15.8215567 18.4205584,13.9701948 16.4473441,13.9701948 C14.4392744,13.9701948 14.1316964,15.5371591 14.1316964,17.1563782 L14.1316964,23.2965454 Z M7.86383929,23.2965454 L4.00446429,23.2965454 L4.00446429,10.888521 L7.86383929,10.888521 L7.86383929,23.2965454 Z" id="LinkedIn"></path>
            </g>
        </g>
    </g>
</svg>
);

export default LinkedInIcon;
