import React from "react";

const ArrowRightIcon = () => (
  <svg width="43px" height="13px" viewBox="0 0 43 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Chapter" transform="translate(-1282.000000, -5687.000000)" fill="#FFFFFF">
            <g id="Group-2" transform="translate(1282.000000, 5687.000000)">
                <polygon id="Triangle" transform="translate(38.500000, 6.500000) scale(1, -1) rotate(-270.000000) translate(-38.500000, -6.500000) " points="38.5 2 45 11 32 11"></polygon>
                <rect id="Rectangle" x="0" y="5" width="37" height="3"></rect>
            </g>
        </g>
    </g>
</svg>
);

export default ArrowRightIcon;
