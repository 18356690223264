import React from "react";

const PlayIcon = () => (
  <svg width="32px" height="32px" viewBox="0 0 32 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 7</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Thinking" transform="translate(-387.000000, -1749.000000)">
            <g id="Group-10" transform="translate(185.000000, 1740.000000)">
                <g id="Group-7" transform="translate(202.000000, 9.000000)">
                    <circle id="Oval" fill="#FFFFFF" cx="16" cy="16" r="16"></circle>
                    <polygon id="Triangle" fill="#323232" transform="translate(17.500000, 16.000000) rotate(-270.000000) translate(-17.500000, -16.000000) " points="17.5 11.5 23.5 20.5 11.5 20.5"></polygon>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default PlayIcon;
