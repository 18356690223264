import React from "react";

const CloseIcon = () => (
  <svg width="19px" height="19px" viewBox="0 0 19 19" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <title>Group 3</title>
    <g id="Design" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="UTILITIES" transform="translate(-1330.000000, -16.000000)">
            <g id="Group-3" transform="translate(1330.000000, 16.000000)">
                <circle id="Oval" fill="#FFFFFF" cx="9.5" cy="9.5" r="9.5"></circle>
                <g id="Group-2" transform="translate(7.000000, 7.000000)" stroke="#FF3D34" strokeLinecap="square" strokeWidth="2">
                    <line x1="0" y1="0" x2="5" y2="5" id="Line-3"></line>
                    <line x1="0" y1="0" x2="5" y2="5" id="Line-3" transform="translate(2.500000, 2.500000) scale(-1, 1) translate(-2.500000, -2.500000) "></line>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default CloseIcon;
