import React from "react";
import { Link } from "gatsby";
import Icon from "./icon";

import * as styles from "../styles/components/project-list-footer.module.css";

function ProjectList({ items }) {
  return (
    <ul className={styles.root}>
      {items.map(({ project, _key }) => {
        return (
          <li className={styles.item} key={_key}>
            {(project.link && project.link.linkUrl) ? (
              <>
              {project.link.externalContent ? 
                (
                  <h6 className={styles.title+" h4"}><a href={project.link.linkUrl} target="_blank" rel="noreferrer">{project.title}<Icon symbol="external" /></a></h6>
                ) : (
                  <h6 className={styles.title+" h4"}><Link to={`${project.link.linkUrl}`}>{project.title}</Link></h6>
                )
              }
              </>
            ) : (
              <h6 className={styles.title+" h4"}>{project.title}</h6>
            )}
            {project.excerpt && (
              <p className={styles.desc}>{project.excerpt}</p>
            )}
          </li>
        );
      })}
    </ul>
  );
}

export default ProjectList;
