import React from "react";
import Logo from "./logo";
import HamburgerIcon from "./hamburger";
import CloseIcon from "./close";
import ExternalIcon from "./external";
import ResetIcon from "./reset";
import ArrowLeftIcon from "./arrow-left";
import ArrowLeftCircleIcon from "./arrow-left-circle";
import ArrowRightIcon from "./arrow-right";
import PlayIcon from "./play";
import QuestionIcon from "./question";
import DocIcon from "./doc";
import LinkedInIcon from "./linkedin";
import InstagramIcon from "./instagram";
import FacebookIcon from "./facebook";

function Icon(props) {
  switch (props.symbol) {
    case "logo":
      return <Logo />;
    case "hamburger":
      return <HamburgerIcon />;
    case "close":
      return <CloseIcon />;
    case "external":
      return <ExternalIcon />;
    case "reset":
      return <ResetIcon />;
    case "arrow-left":
      return <ArrowLeftIcon />;
    case "arrow-left-circle":
      return <ArrowLeftCircleIcon />;
    case "arrow-right":
      return <ArrowRightIcon />;
    case "play":
      return <PlayIcon />;
    case "question":
      return <QuestionIcon />;
    case "doc":
      return <DocIcon />;
    case "linkedin":
      return <LinkedInIcon />;
    case "instagram":
      return <InstagramIcon />;
    case "facebook":
      return <FacebookIcon />;
    default:
      return <span>Unknown icon: {props.symbol}</span>;
  }
}

export default Icon;
